
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { AgeHemophilia } from "../../models/reports/agehemophilia";
import { AGSREPORT_URL } from "@/models/urls";
import { formatPercentage, formatFinancialAmounts } from "../../utils/format";

export default Vue.extend({
  name: "Age",
  props: ["type", "isLatest"],
  data: () => ({
    ageData: [] as AgeHemophilia[],
    isLoading: true,
  }),
  computed: {
    title() {
      let title = `${i18n.t("reports.agsreports.age")} -`;
      switch (this.type) {
        case "1":
          return this.isLatest
            ? `${title} ${i18n.t("reports.agsreports.hemophilia")} A - ${i18n.t(
                "reports.agsreports.latestdata"
              )}`
            : `${title} ${i18n.t("reports.agsreports.hemophilia")} A`;
        case "2":
          return this.isLatest
            ? `${title} ${i18n.t("reports.agsreports.hemophilia")} B - ${i18n.t(
                "reports.agsreports.latestdata"
              )}`
            : `${title} ${i18n.t("reports.agsreports.hemophilia")} B`;
        case "3":
          return this.isLatest
            ? `${title} ${i18n.t("reports.agsreports.hemophilia")} ${i18n.t(
                "reports.agsreports.typeunknown"
              )} - ${i18n.t("reports.agsreports.latestdata")}`
            : `${title} ${i18n.t("reports.agsreports.hemophilia")} ${i18n.t(
                "reports.agsreports.typeunknown"
              )}`;
        case "4":
          return this.isLatest
            ? `${title} vWD - ${i18n.t("reports.agsreports.latestdata")}`
            : `${title} vWD`;
        default:
          return "";
      }
    },
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("basic.country"),
          value: "country",
        },
        {
          text: "0-4",
          value: "zeroToFour",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: "5-13",
          value: "fiveToThirteen",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: "14-18",
          value: "fourteenToEighteen",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: "19-44",
          value: "nineteenToFourtyFour",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: "45+",
          value: "fourtyFiveAndAbove",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("basic.choices.notknown"),
          value: "unknown",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("datamanager.currency.nodata"),
          value: "hasNoData",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.agsreports.totalpatiens"),
          value: "total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: `${i18n.t("reports.agsreports.percentageof")} 0-4`,
          value: "zeroToFourPerc",
          formatter: (val: number): number | string => formatPercentage(val),
        },
        {
          text: `${i18n.t("reports.agsreports.percentageof")} 5-13`,
          value: "fiveToThirteenPerc",
          formatter: (val: number): number | string => formatPercentage(val),
        },
        {
          text: `${i18n.t("reports.agsreports.percentageof")} 14-18`,
          value: "fourteenToEighteenPerc",
          formatter: (val: number): number | string => formatPercentage(val),
        },
        {
          text: `${i18n.t("reports.agsreports.percentageof")} 19-44`,
          value: "nineteenToFourtyFourPerc",
          formatter: (val: number): number | string => formatPercentage(val),
        },
        {
          text: `${i18n.t("reports.agsreports.percentageof")} 45+`,
          value: "fourtyFiveAndAbovePerc",
          formatter: (val: number): number | string => formatPercentage(val),
        },
        {
          text: `${i18n.t("reports.agsreports.percentageof")} ${i18n.t(
            "basic.choices.notknown"
          )}`,
          value: "unknownPerc",
          formatter: (val: number): number | string => formatPercentage(val),
        },
      ];
    },
  },
  methods: {
    getAgeData(year: number) {
      this.isLoading = true;
      axios
        .get(
          `${AGSREPORT_URL}/agehemophilia/${this.type}?year=${year}&isLatest=${this.isLatest}`
        )
        .then((res) => {
          this.ageData = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `${AGSREPORT_URL}/agehemophilia/${this.type}/export?year=${this.selectedSurvey.year}&isLatest=${this.isLatest}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t("navbar.menu.reportsub.ags_reports")} - ${
            this.title
          }`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getAgeData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getAgeData(this.selectedSurvey.year);
    },
    type() {
      if (this.selectedSurvey.year) {
        this.getAgeData(this.selectedSurvey.year);
      }
    },
  },
});
